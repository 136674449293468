<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <div>
    <Fieldset id="paymentCod" :title="$t('Active Payment Methods')">
      <b-row>
        <b-col md="3">
          <select v-model="checkout_type" style="display: none" @change="updatCheckoutType">
            <option value="">
              -- Checkout type --
            </option>
            <option value="km">
              Cambodia
            </option>
            <option value="vn">
              Vietnamese
            </option>
          </select>
          <b-form-group :label="$t('Cash payment on delivery (COD)')" label-cols-md="12">
            <b-form-checkbox
              v-model="cod.is_active"
              class="custom-control-success"
              switch
              @change="updatCod"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group :label="$t('Payment with')+ ` Paypal`" label-cols-md="12">
            <b-form-checkbox
              v-model="st_paypal.is_active"
              class="custom-control-success"
              switch
              @change="updatStPaypal"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group :label="$t('Payment with')+ ` momo`" label-cols-md="12">
            <b-form-checkbox
              v-model="st_momo.is_active"
              class="custom-control-success"
              switch
              @change="updatStMomo"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group :label="$t('Payment with')+ ` bank`" label-cols-md="12">
            <b-form-checkbox
              v-model="st_bank.is_active"
              class="custom-control-success"
              switch
              @change="updatStBank"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group :label="$t('Payment with')+ ` coin`" label-cols-md="12">
            <b-form-checkbox
              v-model="st_coin.is_active"
              class="custom-control-success"
              switch
              @change="updatStCoin"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('Payment with')+ ` Bill24`" label-cols-md="12">
            <b-form-checkbox
              v-model="st_bill24.is_active"
              class="custom-control-success"
              switch
              @change="updatStBill24"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </Fieldset>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col md="7">
              <Fieldset id="paypal" :title="$t('Payment with')+ ` Paypal`">
                <b-form-group label="Client ID:" label-cols-md="12">
                  <b-form-input
                    v-model="model.paypal.client_id"
                  />
                </b-form-group>
                <b-form-group label="Client secret:" label-cols-md="12">
                  <b-form-input
                    v-model="model.paypal.client_secret"
                  />
                </b-form-group>
                <hr>
                <!-- Submit -->
                <div class="text-right">
                  <b-button
                    variant="info"
                    type="submit"
                    class="text-right"
                    @click.prevent="handlerUpdatePaypalAccount"
                  >
                    {{ $t('Save') }}
                  </b-button>
                </div>
              </Fieldset>
              <Fieldset id="bill24" :title="$t('Payment with')+ ` Bill24`">
                <b-form-group label="Token:" label-cols-md="12">
                  <b-form-input
                    v-model="model.bill24.token"
                  />
                </b-form-group>
                <hr>
                <!-- Submit -->
                <div class="text-right">
                  <b-button
                    variant="info"
                    type="submit"
                    class="text-right"
                    @click.prevent="handlerUpdateBill24Account"
                  >
                    {{ $t('Save') }}
                  </b-button>
                </div>
              </Fieldset>
            </b-col>
            <b-col md="5">
              <Fieldset id="momo" :title="$t('Payment with')+ ` Momo`">
                <b-form-group label="Access key:" label-cols-md="12">
                  <b-form-input
                    v-model="model.momo.access_key"
                  />
                </b-form-group>
                <b-form-group label="Secret key:" label-cols-md="12">
                  <b-form-input
                    v-model="model.momo.secret_key"
                  />
                </b-form-group>
                <b-form-group label="Partner code:" label-cols-md="12">
                  <b-form-input
                    v-model="model.momo.partner_code"
                  />
                </b-form-group>
                <hr>
                <!-- Submit -->
                <div class="text-right">
                  <b-button
                    variant="info"
                    type="submit"
                    class="text-right"
                    @click.prevent="handlerUpdateMomoAccount"
                  >
                    {{ $t('Save') }}
                  </b-button>
                </div>
              </Fieldset>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
    <Fieldset id="bankAccount" :title="$t('Payment with bank')">
      <b-form-group>
        <b-button
          id="toggle-btn-bank"
          v-b-modal.modal-bank
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="info"
        >
          {{ $t('Create new') }}
        </b-button>
      </b-form-group>

      <table class="table">
        <thead>
          <tr>
            <th>{{ $t('Logo') }}</th>
            <th>{{ $t('Beneficiary Bank Name') }}</th>
            <th>{{ $t('Beneficiary Account Number') }}</th>
            <th>{{ $t('Beneficiary Name') }}</th>
            <!-- <th>{{ $t('Beneficiary Address') }}</th> -->
            <!-- <th>{{ $t('Beneficiary Bank Address') }}</th> -->
            <th>{{ $t('Swift Code') }}</th>
            <th>{{ $t('Tool') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in bank_account" :key="index">
            <td>
              <img height="50" :src="item.avatar" alt="">
            </td>
            <td>{{ item.bank_name }}</td>
            <td>{{ item.account_number }}</td>
            <td>{{ item.account_name }}</td>
            <!-- <td>{{ item.account_address }}</td> -->
            <!-- <td>{{ item.bank_address }}</td> -->
            <td>{{ item.swift_code }}</td>
            <td>
              <b-button
                variant="danger"
                class="btn-icon"
                size="sm"
                @click="delBank(index)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </Fieldset>

    <Fieldset id="coinAccount" :title="$t('Payment with')+ ` coin`">
      <b-form-group>
        <b-button
          id="toggle-btn-coin"
          v-b-modal.modal-coin
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="info"
        >
          {{ $t('Create new') }}
        </b-button>
      </b-form-group>

      <table class="table">
        <thead>
          <tr>
            <th>Coin name</th>
            <th>Avatar</th>
            <th>QR code</th>
            <th>Address wallet</th>
            <th>Tool</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in coin_account" :key="index">
            <td>{{ item.coin_name }}</td>
            <td>
              <img height="50" :src="item.avatar" alt="">
            </td>
            <td>
              <img height="50" :src="item.qr_code" alt="">
            </td>
            <td>{{ item.address_wallet }}</td>
            <td>
              <b-button
                variant="danger"
                class="btn-icon"
                size="sm"
                @click="delCoin(index)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </Fieldset>

    <!-- modal bank-->
    <b-modal
      id="modal-bank"
      ref="bank-modal-add"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
      centered
      :title="$t('Bank Form')"
      @ok="handleOkBank"
    >
      <validation-observer ref="bank_rule">
        <b-form>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Beneficiary Name"
              rules="required"
            >
              <label>{{ $t('Beneficiary Name') }}</label>
              <b-form-input
                v-model="bank_item.account_name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Beneficiary Address"
              rules="required"
            >
              <label>{{ $t('Beneficiary Address') }}</label>
              <b-form-input
                v-model="bank_item.account_address"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Beneficiary Account Number"
              rules="required"
            >
              <label>{{ $t('Beneficiary Account Number') }}</label>
              <b-form-input
                v-model="bank_item.account_number"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Beneficiary Bank Name"
              rules="required"
            >
              <label>{{ $t('Beneficiary Bank Name') }}</label>
              <b-form-input
                v-model="bank_item.bank_name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Beneficiary Bank Address"
              rules="required"
            >
              <label>{{ $t('Beneficiary Bank Address') }}</label>
              <b-form-input
                v-model="bank_item.bank_address"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Bank SWIFT Code"
              rules="required"
            >
              <label>{{ $t('Bank SWIFT Code') }}</label>
              <b-form-input
                v-model="bank_item.swift_code"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label>Logo</label>
            <FeatureImage :avatar="bank_item.avatar" @previewFiles="previewAvatar" />
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- modal coin-->
    <b-modal
      id="modal-coin"
      ref="coin-modal-add"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
      centered
      title="Coin Form"
      @ok="handleOkCoin"
    >
      <validation-observer ref="coin_rule">
        <b-form>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Coin name"
              rules="required"
            >
              <label>Coin name</label>
              <b-form-input
                v-model="coin_item.coin_name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Address wallet"
              rules="required"
            >
              <label>Address wallet</label>
              <b-form-input
                v-model="coin_item.address_wallet"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label>Avatar</label>
            <FeatureImage :avatar="coin_item.avatar" @previewFiles="previewCoinImg($event, 'avatar')" />
          </b-form-group>
          <b-form-group>
            <label>QR code</label>
            <FeatureImage :avatar="coin_item.qr_code" @previewFiles="previewCoinImg($event, 'qr_code')" />
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const isBase64 = require('is-base64')

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      key: 'buso_client_page_config',
      isExistSetting: false,
      config: [],
      required,
      old: {},
      model: {
        paypal: {
          client_id: null,
          client_secret: null,
        },
        momo: {
          access_key: null,
          secret_key: null,
          partner_code: null,
        },
        bitcoin: {
          address_wallet: null,
        },
        bill24: {
          token: null,
        },
      },
      bank_account: [],
      bank_item: {
        avatar: null,
        bank_name: '',
        bank_address: '',
        account_number: '',
        account_name: '',
        account_address: '',
        swift_code: '',
      },
      coin_account: [],
      coin_item: {
        avatar: null,
        qr_code: null,
        coin_name: '',
        address_wallet: '',
      },
      checkout_type: '',
      cod: {
        is_active: false,
      },
      st_momo: {
        is_active: false,
      },
      st_paypal: {
        is_active: false,
      },
      st_bank: {
        is_active: false,
      },
      st_coin: {
        is_active: false,
      },
      st_bill24: {
        is_active: false,
      },
    }
  },
  mounted() {
    this.loadSiteInfo()
    this.loadConfig()
  },
  methods: {
    delBank(index) {
      this.bank_account.splice(index, 1)
      this.handlerUpdateBankAccount()
    },
    delCoin(index) {
      this.coin_account.splice(index, 1)
      this.handlerUpdateCoinAccount()
    },
    async saveBank() {
      const item = {
        avatar: await this.handlerUpLoadImg(this.bank_item.avatar, 'bank_avatar'),
        bank_name: this.bank_item.bank_name,
        bank_address: this.bank_item.bank_address,
        account_number: this.bank_item.account_number,
        account_name: this.bank_item.account_name,
        account_address: this.bank_item.account_address,
        swift_code: this.bank_item.swift_code,
      }
      this.bank_account.push(item)
      this.handlerUpdateBankAccount()
    },
    async saveCoin() {
      const item = {
        avatar: await this.handlerUpLoadImg(this.coin_item.avatar, 'coin_avatar'),
        qr_code: await this.handlerUpLoadImg(this.coin_item.qr_code, 'coin_qr_code'),
        coin_name: this.coin_item.coin_name,
        address_wallet: this.coin_item.address_wallet,
      }
      this.coin_account.push(item)
      this.handlerUpdateCoinAccount()
    },
    handleOkBank(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.$refs.bank_rule.validate().then(success => {
        if (success) {
          this.saveBank()
          this.$nextTick(() => {
            this.$refs['bank-modal-add'].toggle('#toggle-btn-bank')
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: 'Error',
            },
          })
        }
      })
    },
    handleOkCoin(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.$refs.coin_rule.validate().then(success => {
        if (success) {
          this.saveCoin()
          this.$nextTick(() => {
            this.$refs['coin-modal-add'].toggle('#toggle-btn-coin')
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: 'Error',
            },
          })
        }
      })
    },
    previewAvatar(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.bank_item.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
    previewCoinImg(input, name) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.coin_item[name] = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
    async handlerUpLoadImg(base64, type) {
      if (isBase64(base64, { allowMime: true })) {
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          type_table: 's_t',
          type: 'desktop',
          avatar: base64,
          table_field: type,
        }
        const res = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_IMG_URL}/images_only`,
          params,
        )
        if (res.status === 200) {
          return res.data.data.image
        }
      }
      return base64
    },
    async loadConfig() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/setting/${this.key}?site_id=${
            JSON.parse(localStorage.getItem('siteID')).id
          }`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.isExistSetting = true
            this.config = JSON.parse(res.data.data.value)
            this.bank_account = JSON.parse(res.data.data.value).find(val => val.key === 'bank') !== undefined ? JSON.parse(JSON.parse(res.data.data.value).find(val => val.key === 'bank').value) : this.bank_account
            this.coin_account = JSON.parse(res.data.data.value).find(val => val.key === 'coin') !== undefined ? JSON.parse(JSON.parse(res.data.data.value).find(val => val.key === 'coin').value) : this.coin_account

            this.cod = JSON.parse(res.data.data.value).find(val => val.key === 'cod') !== undefined ? JSON.parse(JSON.parse(res.data.data.value).find(val => val.key === 'cod').value) : this.cod

            this.st_momo = JSON.parse(res.data.data.value).find(val => val.key === 'st_momo') !== undefined ? JSON.parse(JSON.parse(res.data.data.value).find(val => val.key === 'st_momo').value) : this.st_momo

            this.st_paypal = JSON.parse(res.data.data.value).find(val => val.key === 'st_paypal') !== undefined ? JSON.parse(JSON.parse(res.data.data.value).find(val => val.key === 'st_paypal').value) : this.st_paypal

            this.st_bank = JSON.parse(res.data.data.value).find(val => val.key === 'st_bank') !== undefined ? JSON.parse(JSON.parse(res.data.data.value).find(val => val.key === 'st_bank').value) : this.st_bank

            this.st_coin = JSON.parse(res.data.data.value).find(val => val.key === 'st_coin') !== undefined ? JSON.parse(JSON.parse(res.data.data.value).find(val => val.key === 'st_coin').value) : this.st_coin

            this.st_bill24 = JSON.parse(res.data.data.value).find(val => val.key === 'st_bill24') !== undefined ? JSON.parse(JSON.parse(res.data.data.value).find(val => val.key === 'st_bill24').value) : this.st_bill24

            this.checkout_type = JSON.parse(res.data.data.value).find(val => val.key === 'checkout_type') !== undefined ? JSON.parse(res.data.data.value).find(val => val.key === 'checkout_type').value : this.checkout_type
          } else {
            this.isExistSetting = false
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadSiteInfo() {
      try {
        const res = await Request.get(this.$http, `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}?user_id=${JSON.parse(localStorage.getItem('token')).id}`)
        if (res.status === 200) {
          if (res.data.status) {
            this.old = res.data.data
            if (res.data.data.account_momo !== null) {
              const momo = JSON.parse(res.data.data.account_momo)
              this.model.momo = {
                mode: 'sandbox',
                access_key: momo.access_key,
                secret_key: momo.secret_key,
                partner_code: momo.partner_code,
              }
            }
            if (res.data.data.account_paypal !== null) {
              const paypal = JSON.parse(res.data.data.account_paypal)
              this.model.paypal = {
                mode: 'sandbox',
                client_id: paypal.client_id,
                client_secret: paypal.client_secret,
              }
            }
            if (res.data.data.token_bill24 !== null) {
              const bill24 = JSON.parse(res.data.data.token_bill24)
              this.model.bill24 = {
                token: bill24.token,
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    handlerUpdateMomoAccount() {
      const params = {
        name: this.old.name,
        account_momo: JSON.stringify(this.model.momo),
        is_active: 1,
      }
      this.handlerUpdate(params, 'Update Momo Account success')
    },
    handlerUpdatePaypalAccount() {
      const params = {
        name: this.old.name,
        account_paypal: JSON.stringify(this.model.paypal),
        is_active: 1,
      }
      this.handlerUpdate(params, 'Update Paypal Account success')
    },
    handlerUpdateBill24Account() {
      const params = {
        name: this.old.name,
        token_bill24: JSON.stringify(this.model.bill24),
        is_active: 1,
      }
      this.handlerUpdate(params, 'Update Bill24 Account success')
    },
    async handlerUpdate(params, textNoti) {
      try {
        const res = await Request.put(
          this.$http,
          `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Congratulation !',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: textNoti,
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async updatCheckoutType() {
      if (this.config.find(val => val.key === 'checkout_type') === undefined) {
        this.config.push({
          key: 'checkout_type',
          value: JSON.stringify(this.cod),
        })
      } else {
        this.config.find(val => val.key === 'checkout_type').value = this.checkout_type
      }
      this.handlerUpdateConfig(this.config, 'Update success')
    },
    async updatCod() {
      if (this.config.find(val => val.key === 'cod') === undefined) {
        this.config.push({
          key: 'cod',
          value: JSON.stringify(this.cod),
        })
      } else {
        this.config.find(val => val.key === 'cod').value = JSON.stringify(this.cod)
      }
      this.handlerUpdateConfig(this.config, 'Update success')
    },
    async updatStMomo() {
      if (this.config.find(val => val.key === 'st_momo') === undefined) {
        this.config.push({
          key: 'st_momo',
          value: JSON.stringify(this.st_momo),
        })
      } else {
        this.config.find(val => val.key === 'st_momo').value = JSON.stringify(this.st_momo)
      }
      this.handlerUpdateConfig(this.config, 'Update success')
    },
    async updatStBank() {
      if (this.config.find(val => val.key === 'st_bank') === undefined) {
        this.config.push({
          key: 'st_bank',
          value: JSON.stringify(this.st_bank),
        })
      } else {
        this.config.find(val => val.key === 'st_bank').value = JSON.stringify(this.st_bank)
      }
      this.handlerUpdateConfig(this.config, 'Update success')
    },

    async updatStCoin() {
      if (this.config.find(val => val.key === 'st_coin') === undefined) {
        this.config.push({
          key: 'st_coin',
          value: JSON.stringify(this.st_coin),
        })
      } else {
        this.config.find(val => val.key === 'st_coin').value = JSON.stringify(this.st_coin)
      }
      this.handlerUpdateConfig(this.config, 'Update success')
    },

    async updatStBill24() {
      if (this.config.find(val => val.key === 'st_bill24') === undefined) {
        this.config.push({
          key: 'st_bill24',
          value: JSON.stringify(this.st_coin),
        })
      } else {
        this.config.find(val => val.key === 'st_bill24').value = JSON.stringify(this.st_bill24)
      }
      this.handlerUpdateConfig(this.config, 'Update success')
    },

    async updatStPaypal() {
      if (this.config.find(val => val.key === 'st_paypal') === undefined) {
        this.config.push({
          key: 'st_paypal',
          value: JSON.stringify(this.st_paypal),
        })
      } else {
        this.config.find(val => val.key === 'st_paypal').value = JSON.stringify(this.st_paypal)
      }
      this.handlerUpdateConfig(this.config, 'Update success')
    },

    async handlerUpdateCoinAccount() {
      if (this.config.find(val => val.key === 'coin') === undefined) {
        this.config.push({
          key: 'coin',
          value: JSON.stringify(this.coin_account),
        })
      } else {
        this.config.find(val => val.key === 'coin').value = JSON.stringify(this.coin_account)
      }
      this.handlerUpdateConfig(this.config, 'Update coin account success')
    },
    async handlerUpdateBankAccount() {
      if (this.config.find(val => val.key === 'bank') === undefined) {
        this.config.push({
          key: 'bank',
          value: JSON.stringify(this.bank_account),
        })
      } else {
        this.config.find(val => val.key === 'bank').value = JSON.stringify(this.bank_account)
      }
      this.handlerUpdateConfig(this.config, 'Update bank account success')
    },
    async handlerUpdateConfig(config, textNoti) {
      try {
        let res = null
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          key: this.key,
          value: JSON.stringify(config),
        }
        if (!this.isExistSetting) {
          res = await Request.post(
            this.$http,
            `${process.env.VUE_APP_API_URL}/settings`,
            params,
          )
        } else {
          res = await Request.put(
            this.$http,
            `${process.env.VUE_APP_API_URL}/setting/${this.key}`,
            params,
          )
        }
        if (res.status === 200) {
          if (res.data.status) {
            // this.loadConfig()
            this.isExistSetting = true
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Congratulation !',
                icon: 'CheckCircleIcon',
                variant: 'Success',
                text: textNoti,
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    repeatAccount() {
      this.bank_account.push({
        name: `bank_account_${this.bank_account.length + 1}`,
        value: [{
          key: null,
          value: null,
        }],
      })
    },
    repeatOptions(index) {
      this.bank_account[index].value.push({
        key: null,
        value: null,
      })
    },
    removeOptions(parentIndex, index) {
      this.bank_account[parentIndex].value.splice(index, 1)
      if (this.bank_account[parentIndex].value.length <= 0) {
        this.bank_account.splice(parentIndex, 1)
      }
      if (this.bank_account.length <= 0) {
        this.bank_account.push({
          name: 'bank_account_1',
          value: [{
            key: null,
            value: null,
          }],
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.inline-block {
  display: block;
}
.bg-gray {
  background: #f6f6f6;
  padding: 12px 24px;
}
.text-1 {
  font-size: 1rem;
}
table tr td {
  border: 1px solid #ebe9f1;
}
.fade-browse {
  border: 1px dashed var(--primary);
  color: var(--primary);
  border-radius: 4px;
  padding: 8px 12px;
  text-align: center;
  position: relative;
  &.add-big {
    border: 1px dashed var(--secondary);
    max-width: 200px;
    margin: auto;
    background: var(--secondary);
    color: #fff
  }
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
